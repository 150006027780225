import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout.jsx";
import Home from "./pages/Home.js";
import Closed from "./pages/Closed.jsx";
import Orders from "./pages/Orders.js";
import Orderstatus from "./pages/Orderstatus.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

// import App from "./App";
// import reportWebVitals from "./reportWebVitals";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="orderstatus/:id" element={<Orderstatus />} />
          <Route path="orderlista" element={<Orders />} />
          <Route path="*" element={<Closed />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
