import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

const Cart = (cart) => {
  const [show, setShow] = useState(false);
  const [order, setOrder] = useState(null);
  const [orderShow, setOrderShow] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [userName, setUserName] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOrder = () => {
    const data = {
      uuid: order.uuid,
      payerName: userName,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      `${process.env.REACT_APP_API_HOST}/api/orders/createpayment`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        try {
          window.location = `swish://paymentrequest?token=${data.paymentRequestToken}&callbackurl=${process.env.REACT_APP_HOST}/orderstatus/${order.uuid}`;
        } catch (error) {
          <Alert variant="danger">
            <Alert.Heading>Fel!</Alert.Heading>
            <p>Swish är inte installerat på denna enhet.</p>
          </Alert>;
        }
      });
  };

  const handleOrderClose = () => setOrderShow(false);
  const handleOrderShow = () => {
    setOrderShow(true);
    setOrderLoading(true);
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(cart.items),
    };
    fetch(`${process.env.REACT_APP_API_HOST}/api/orders`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setOrder(data);
        setOrderLoading(false);
      });
  };

  const [total, setTotal] = useState(0);

  useEffect(() => {
    let sum = 0;
    cart.items.forEach((item) => {
      sum += item.price * item.qty;
    });

    setTotal(sum);
  }, [cart]);

  const handleChange = (event) => {
    // 👇 Get input value from "event"
    setUserName(event.target.value);
  };

  return (
    <>
      <nav className="bg-secondary text-uppercase fixed-top" id="mainNav">
        <Container>
          <Row>
            <Col xs={7} className="navbar-brand">
              <div
                style={{
                  fontSize: "0.6em",
                }}
              >
                Mölndalsrevyn
              </div>
              <h1
                style={{
                  fontSize: "1.7em",
                  textTransform: "capitalize",
                }}
              >
                Pausfika
              </h1>
            </Col>
            <Col
              xs={5}
              className="navbar-brand"
              style={{
                fontSize: "2.1em",
                textTransform: "capitalize",
                marginTop: "5px",
              }}
            >
              <span
                style={{
                  fontSize: "0.5em",
                  textTransform: "capitalize",
                  marginTop: "5px",
                }}
              >
                Totalt:
              </span>{" "}
              {total}:-
            </Col>

            <Col xs={6}>
              <Button
                style={{ margin: "10px 0 0 0" }}
                variant="info"
                onClick={handleShow}
              >
                Info
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                style={{ margin: "10px 0 0 0" }}
                variant="primary"
                disabled={total < 1} // isMobile
                onClick={handleOrderShow}
              >
                <b>Visa beställning</b>
              </Button>{" "}
            </Col>
          </Row>
        </Container>
      </nav>
      <Modal fullscreen show={orderShow} onHide={handleOrderClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Beställning <br />
            {!orderLoading && order && (
              <span style={{ fontSize: "80%", color: "#999" }}>
                Referens: {order.orderRef}
              </span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {orderLoading && (
            <Spinner className="spinner" animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          {!orderLoading && order && (
            <Table striped borderless hover size="sm">
              <thead>
                <tr>
                  <th style={{ textAlign: "right" }}>Antal</th>
                  <th>Namn</th>
                  <th>Pris</th>
                  <th>Summa</th>
                </tr>
              </thead>
              <tbody>
                {order?.items &&
                  order.items.map((item) => (
                    <tr key={item.id}>
                      <td style={{ textAlign: "right" }}>{item.qty} st</td>
                      <td>{item.name}</td>
                      <td style={{ textAlign: "right" }}>{item.price}:-</td>
                      <td style={{ textAlign: "right" }}>
                        {item.price * item.qty}:-
                      </td>
                    </tr>
                  ))}

                <tr>
                  <td colSpan={3} style={{ textAlign: "right" }}>
                    <b>Totalt:</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <b>{order.total}:-</b>
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          {order && !orderLoading && (
            <>
              <Form.Control
                type="text"
                id="userName"
                aria-describedby="passwordHelpBlock"
                placeholder="Lägg till ditt namn på beställningen"
                onChange={handleChange}
              />
              <Button
                disabled={order.total < 1 || userName.length < 3}
                variant="primary"
                onClick={handleOrder}
              >
                <b>Betala med Swish</b>
              </Button>
            </>
          )}
          <Button variant="secondary" onClick={handleOrderClose}>
            Stäng
          </Button>
          {!orderLoading && order && order.ageLimit > 0 && (
            <>
              <br />
              <div>
                <em
                  style={{
                    color: "red",

                    fontSize: "80%",
                  }}
                >
                  &#9888; Du kan bara genomföra betalningen om du är över{" "}
                  {order.ageLimit} år
                </em>
              </div>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Hej! Använd den här funktionen för att i lugn och ro göra din
          beställning till Mölndalsrevyns pausfika utan att behöva stå i kö. Din
          beställning kommer att serveras till pausen.
          <br />
          <br />
          <ul>
            <li>
              Det går bara att beställa från en mobil med <b>Swish</b> och{" "}
              <b>BankId</b>.
            </li>
            <li>Du kan inte beställa alkohol om du är under 18 år.</li>
            <li>
              Du kan beställa från två timmar före föreställningen ända fram
              tills föreställningen börjar.
            </li>
            <li>
              Du kan boka flera gånger - alla beställningar levereras till samma
              sällskap.
            </li>
            <li>
              Vill du inte använda mobiltelefonen så kan du givetvis beställa
              fikat direkt från personalen i caféet.
            </li>
            <li>
              Om du har någon matallergi, kolla alltid med personalen på plats
              innan du beställer.
            </li>
            <li>
              De personuppgifter som används vid hanteringen av din order
              raderas direkt efter föreställningens slut.
            </li>
            <li>Inga återköp.</li>
            <li>Fråga cafépersonalen om du har några frågor.</li>
          </ul>
          <p>Välkommen! Vi önskar dig en trevlig kväll på Möllan.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Stäng
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Cart;
