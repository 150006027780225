import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Cart from "../components/Cart.js";
import OrderForm from "../components/OrderForm.js";
import { BrowserView, MobileView } from "react-device-detect";
import Alert from "react-bootstrap/Alert";

const Home = () => {
  const [loaded, setLoaded] = useState(false);
  const [cart, setCart] = useState([]);
  const [open, setOpen] = useState(false);

  async function getOpen() {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/open`);
    const data = await response.json();
    setOpen(data.open);
    setLoaded(true);
  }

  function handleChange(item, counter) {
    if (counter > 0) {
      addToCart(item);
    }
    if (counter < 0) {
      removeFromCart(item);
    }
  }

  const addToCart = (el) => {
    let hardCopy = [...cart];
    var objIndex = hardCopy.findIndex((obj) => obj.id === el.id);
    if (objIndex < 0) {
      hardCopy = [...hardCopy, el];
      objIndex = hardCopy.findIndex((obj) => obj.id === el.id);
      hardCopy[objIndex].qty = 0;
    }
    objIndex = hardCopy.findIndex((obj) => obj.id === el.id);
    hardCopy[objIndex].qty += 1;
    setCart([...hardCopy]);
  };

  const removeFromCart = (el) => {
    let hardCopy = [...cart];
    var objIndex = hardCopy.findIndex((obj) => obj.id === el.id);
    if (hardCopy[objIndex]?.qty > 0) hardCopy[objIndex].qty -= 1;
    if (hardCopy[objIndex]?.qty === 0)
      hardCopy = hardCopy.filter((cartItem) => cartItem.id !== el.id);
    setCart([...hardCopy]);
  };

  useEffect(() => {
    getOpen();
  }, []);

  return (
    <>
      <BrowserView>
        <Container style={{ margin: "10px 0 0 0" }}>
          <Alert variant="warning">
            <Alert.Heading>Ogiltig enhet</Alert.Heading>
            <p>
              Den här funktionen kan bara användas på en mobiltelefon med{" "}
              <b>Swish</b> och <b>BankID</b>.
            </p>
          </Alert>
        </Container>
      </BrowserView>

      <MobileView>
        {loaded && !open && (
          <div style={{ margin: "10px 0 0 0" }}>
            <Container fluid>
              <Alert variant="warning">
                <Alert.Heading>
                  Beställningsfunktionen är för tillfället stängd
                </Alert.Heading>
                <p>
                  Sidan för att beställa fika till pausen öppnar vanligtvis två
                  timmar innan föreställningen börjar, och stängs när första
                  akten kört igång.
                </p>
                <p>
                  Om denna funktion är stängd kan du ändå köpa fika som vanligt
                  av personalen i pausen.
                </p>
              </Alert>
            </Container>
          </div>
        )}

        {loaded && open && (
          <div style={{ margin: "80px 0 0 0" }}>
            <Cart items={cart}></Cart>
            <OrderForm handleChange={handleChange}></OrderForm>
          </div>
        )}
      </MobileView>
    </>
  );
};

export default Home;
