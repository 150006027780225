import { useState, useEffect, useRef } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { useParams } from "react-router";

const Order = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [paymentrequest, setPaymentrequest] = useState({});
  const orderRef = useRef(order);
  const paymentrequestRef = useRef(paymentrequest);

  const getOrder = async (uuid) => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/orders/${uuid}`
    );
    if (response.status < 300) {
      const data = await response.json();
      setOrder(data);
      orderRef.current = data;
    } else {
      setOrder({});
    }
    setLoading(false);
  };

  const getPaymentRequest = async (uuid) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/swish/paymentrequests/${uuid}`
    );
    if (response.status < 300) {
      const data = await response.json();
      setPaymentrequest(data);
      paymentrequestRef.current = data;
    } else {
      setPaymentrequest({});
      paymentrequestRef.current = {};
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      // 👇️ redirects to an external URL
      window.location.replace("/");
    }, 90000);

    getOrder(id);
    getPaymentRequest(id);
    const interval = setInterval(() => {
      getPaymentRequest(id);
      if (
        !paymentrequestRef.current.status ||
        paymentrequestRef.current.status === "CREATED"
      ) {
      } else clearInterval(interval);
    }, 2 * 1000);

    return () => {
      clearInterval(timeout);
      clearInterval(interval);
    };
  }, [id]);

  return (
    <>
      <Container>
        <h1>Din beställning</h1>
      </Container>

      {loading && (
        <Container
          style={{
            marginTop: "50px",
            marginBottom: "50px",
            textAlign: "center",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Container>
      )}

      {!loading && JSON.stringify(order) === "{}" && (
        <Container>Order saknas</Container>
      )}

      {!loading && JSON.stringify(order) !== "{}" && (
        <Container>
          <div>Referens: {order.orderRef}</div>
          <div>Totalt: {order.total}:-</div>
          {order.payerName && <div>Namn: {order.payerName}</div>}
          {paymentrequest.payerAlias && (
            <div>Telefon: +{paymentrequest.payerAlias}</div>
          )}
          {paymentrequest.payeeAlias && (
            <div>Swish: {paymentrequest.payeeAlias}</div>
          )}
          <div>
            Status:
            {order.swish.status === "UNKNOWN" && !paymentrequest.status && (
              <span style={{ color: "red" }}> Ej påbörjad</span>
            )}
            {paymentrequest.status === "CREATED" && (
              <>
                <span style={{ color: "red" }}> Inväntar betalning</span>
                <span> </span>
                <Spinner
                  as="span"
                  animation="grow"
                  variant="secondary"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            )}
            {paymentrequest.status === "PAID" && (
              <span style={{ color: "green" }}> Betald</span>
            )}
            {paymentrequest.status === "CANCELLED" && (
              <span style={{ color: "red" }}> Avbruten</span>
            )}
            {paymentrequest.status === "DECLINED" && (
              <span style={{ color: "red" }}> Avbruten</span>
            )}
            {paymentrequest.status === "ERROR" && (
              <span style={{ color: "red" }}> Avbruten</span>
            )}
            {paymentrequest.errorMessageFriendly &&
              paymentrequest.errorMessage && (
                <em> ({paymentrequest.errorMessageFriendly})</em>
              )}
          </div>
          <p>
            {paymentrequest.status === "PAID" && (
              <Alert variant="success">
                <p>
                  Din beställning är nu betald och kommer att serveras i pausen
                  av cafépersonalen.
                </p>
              </Alert>
            )}
          </p>
        </Container>
      )}
      <br />
      {order.items && (
        <Container>
          <Table striped bordered hover style={{ marginTop: "10px" }}>
            <thead>
              <tr>
                <th>Antal</th>
                <th>Namn</th>
                <th>Pris</th>
                <th>Totalt</th>
              </tr>
            </thead>
            <tbody>
              {order.items.map((item) => (
                <tr key={item.id}>
                  <td style={{ textAlign: "right" }}>{item.qty}</td>
                  <td>{item.name}</td>
                  <td style={{ textAlign: "right" }}>{item.price}:-</td>
                  <td style={{ textAlign: "right" }}>
                    {item.qty * item.price}:-
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={3} style={{ textAlign: "right" }}></td>
                <td style={{ textAlign: "right" }}>
                  <b>{order.total}:-</b>
                </td>
              </tr>
            </tbody>
          </Table>
        </Container>
      )}
      <Container>
        <a href="/">Till startsidan</a>
      </Container>
    </>
  );
};

export default Order;
