import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import { useSearchParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";

const Orders = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [orders, setOrders] = useState([]);
  const [time, setTime] = useState();

  const getOrders = async () => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/orders`
    );
    const data = await response.json();
    setOrders(data);
    setLoading(false);
    setLoaded(true);
  };

  useEffect(() => {
    setTime(Date.now());
    getOrders();
    const interval = setInterval(() => {
      setTime(Date.now());
      getOrders();
    }, 30 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {process.env.REACT_APP_KEY !== searchParams.get("key") && (
        <Container style={{ margin: "10px 0 0 0" }}>
          <Alert variant="danger">
            <Alert.Heading>Ogiltigt sökväg</Alert.Heading>
            <p>Du har inte tillgång till den här sidan.</p>
          </Alert>
        </Container>
      )}
      {""}
      {process.env.REACT_APP_KEY === searchParams.get("key") && (
        <>
          <Container>
            <h1>Beställningar</h1>
            <div>
              {time && new Date(time).toLocaleDateString("sv-SE")}
              &nbsp;
              {time &&
                new Date(time).toLocaleTimeString("sv-SE").substring(0, 5)}
            </div>
          </Container>
          <Container>
            {loading && (
              <Spinner
                className="spinner"
                animation="grow"
                size="sm"
                role="status"
              >
                <span className="visually-hidden">Laddar...</span>
              </Spinner>
            )}
            {orders && orders.length > 0 && (
              <Table striped bordered hover style={{ marginTop: "10px" }}>
                <thead>
                  <tr>
                    <th>Order</th>
                    <th>Beställt</th>
                    <th>Totalt</th>
                    <th>Beställare</th>
                    <th>Skapad</th>
                    <th>Plockad</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((ord) => (
                    <tr key={ord.orderId}>
                      <td>
                        {ord.orderRef}
                        {ord.ageLimit > 0 && (
                          <span style={{ color: "red" }}> &#9888;</span>
                        )}
                      </td>
                      <td>
                        {ord.items.map((item) => (
                          <div key={ord.orderId + ":" + item.name}>
                            <b>
                              {item.qty} st {item.name}
                            </b>{" "}
                            ({item.price}:-)
                          </div>
                        ))}
                      </td>
                      <td style={{ textAlign: "right" }}>{ord.total}:-</td>
                      <td>
                        {ord.payerName}
                        <br />
                        (0{ord.payerAlias.substring(2)})
                      </td>
                      <td>
                        {new Date(ord.createdAt)
                          .toLocaleDateString("sv-SE")
                          .substring(5) +
                          " " +
                          new Date(ord.createdAt)
                            .toLocaleTimeString("sv-SE")
                            .substring(0, 5)}
                      </td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {!loading && loaded && orders.length < 1 && (
              <div style={{ marginTop: "10px" }}>
                <em>Det finns för tillfället inga beställningar.</em>
              </div>
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default Orders;
