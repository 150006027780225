import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";

const MenuItem = ({ item, onItemChange }) => {
  const [counter, setCounter] = useState(0);

  function project(obj, projection) {
    let projectedObj = {};
    for (let key in projection) {
      projectedObj[key] = obj[key];
    }
    return projectedObj;
  }

  function handleMinusClick() {
    setCounter((prevCount) => {
      if (prevCount < 1) return 0;
      else return prevCount - 1;
    });

    var obj = project(item, { id: 1, price: 1 });
    onItemChange(obj, -1);
  }

  function handlePlusClick() {
    setCounter((prevCount) => prevCount + 1);
    var obj = project(item, { id: 1, price: 1 });
    onItemChange(obj, 1);
  }

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>{item.name} </td>
                  <td
                    style={{
                      width: "100px",
                      textAlign: "right",
                    }}
                  >
                    {item.price}:-
                  </td>
                </tr>
              </tbody>
            </table>
          </Card.Title>
          <Card.Text>
            {item.description}
            {item.ageLimit > 0 && (
              <div style={{ color: "red", marginTop: "0.5em" }}>
                &#9888; Min {item.ageLimit} år <br />
              </div>
            )}
            <ButtonGroup
              style={{
                marginTop: "1em",
                display: "flex",
                justifyContent: "center",
                alignItem: "center",
              }}
            >
              <Button
                variant="secondary"
                onClick={() => {
                  handleMinusClick();
                }}
              >
                -
              </Button>
              <Button variant="secondary" style={{ width: "15rem" }}>
                {counter > 0 ? <>{counter} st</> : <>Ingen vald</>}
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  handlePlusClick();
                }}
              >
                +
              </Button>
            </ButtonGroup>
          </Card.Text>
        </Card.Body>
      </Card>
      <br />
    </>
  );
};

export default MenuItem;
